import React from 'react';

export default function Loading() {
  return (
    <div className='fixed top-0 left-0 w-screen h-screen z-50'>
      <div className='flex gap-5 flex-col justify-center items-center w-full h-full bg-white bg-opacity-90'>
        <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900'></div>
        <h1>Уншиж байна...</h1>
      </div>
    </div>
  );
}
