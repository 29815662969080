import React from 'react';

export default function AboutSection({ img, title, descs = [''], reverse = false }) {
  return (
    <div
      id='about-us'
      className='px-5 py-5 mx-auto flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow xl:flex-row md:max-w-xl xl:max-w-[80vw] xl:w-[80vw] hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700'>
      <img
        className='w-full object-cover xl:w-[30vw] rounded-t-lg h-96 md:h-auto   md:rounded-none md:rounded-s-lg'
        src={img}
        alt=''
      />
      <div className='flex flex-col justify-between p-4 leading-normal'>
        <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white'>{title}</h5>
        {descs.map((item, index) => (
          <p key={index} className='mb-3 font-normal text-gray-700 dark:text-gray-400'>
            {item}
          </p>
        ))}
      </div>
    </div>
  );
}
