import React from 'react';

export default function Footer() {
  return (
    <footer id='footer' className='bg-white rounded-lg shadow dark:bg-gray-900 m-4'>
      <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
        <div className='sm:flex sm:flex-wrap sm:items-center sm:justify-between'>
          <a href='#home' rel='noreferrer' className='flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse'>
            <img src='/logo.png' className='h-8' alt='Flowbite Logo' />
            <span className='self-center text-xl font-semibold whitespace-nowrap dark:text-white'>MDPOST</span>
          </a>
          <ul className='flex  flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400'>
            <li>
              <a
                href='https://www.facebook.com/groups/489150573967349'
                target='_blank'
                rel='noreferrer'
                className='flex gap-1 hover:underline me-4 md:me-6'>
                <img src='/fb-logo.webp' className='w-5 h-5' alt='logo' />
                Facebook
              </a>
            </li>
            <li>
              <a
                href='https://maps.app.goo.gl/gzomHD7jfLFMB6wV7'
                rel='noreferrer'
                target='_blank'
                className='hover:underline me-4 md:me-6'>
                Хаяг: 23, СХД - 38 хороо, Улаанбаатар 18101
              </a>
            </li>
            <li>
              <a href='tel:8080-9379' className='hover:underline me-4 md:me-6'>
                Утас: 8080-9379
              </a>
            </li>
            <li>
              <a href='mailto:Atomymdpost@gmail.com' rel='noreferrer' target='_blank' className='hover:underline'>
                И-мэйл: Atomymdpost@gmail.com
              </a>
            </li>
          </ul>
        </div>
        <hr className='my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8' />
        <span className='block text-sm text-gray-500 sm:text-center dark:text-gray-400'>
          © 2024
          <a href='#home' className=' mx-3 hover:underline'>
            MDPOST
          </a>
          Бүх эрх хуулиар хамгаалагдсан
        </span>
      </div>
    </footer>
  );
}
