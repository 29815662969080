import { useEffect, useState } from 'react';
import * as xlsx from 'xlsx';
import Footer from './components/footer';
import Navbar from './components/navbar';
import AboutSection from './components/about-section';
import Loading from './components/Loading';

function App() {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(undefined);

  const url = 'https://docs.google.com/spreadsheets/d/1Z_Y2dz45fNVLQZoxnbnRun69eMdrRym8k5LKhf1NWGs/pub?gid=224372971&single=true&output=csv';

  async function checkOrder() {
    setLoading(true);
    await fetch(url)
      .then((response) => response.text())
      .then((data) => {
        const a = xlsx.read(data, { type: 'binary' });
        const SheetName = a.SheetNames[0];
        const sheets = a.Sheets[SheetName];
        let result = xlsx.utils.sheet_to_json(sheets);
        
        result = result.find((item) => typeof item['OrderId'] === 'string' && item['OrderId'].slice(0,12) === input);
        console.log(result);

        if (result) {
          setOrder(result);
        } else {
          setOrder(null);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        
        alert('Алдаа гарлаа: ', e?.message);
      }).catch((e) => {
        setLoading(false);
        console.log('err',e);
        
        alert('Алдаа гарлаа: ', e?.message);
      }
      );
  }

  useEffect(() => {
    let data = window.location.search
    const id = (new URLSearchParams(data)).get('order')
    setInput(id)
    if (id) {
      checkOrder()
    }
    // eslint-disable-next-line
  }, [])
  

  return (
    <div
      className='pb-5'
      id='home'
      onKeyPress={(e) => {
        e.key === 'Enter' && checkOrder();
      }}>
      <div style={{ minHeight: 'calc(100vh - 220px)' }}>
        <Navbar />
        {loading && <Loading />}
        <div className='bg-cover bg-[url("./assets/bg2.jpg")]'>
          <div
            className='py-16 flex flex-col gap-5 justify-center items-center w-full '
            style={{ backdropFilter: 'blur(0px)' }}>
            <div className='bg-white bg-opacity-70 px-6 py-4 rounded-2xl'>
              <div className=''>
                <label htmlFor='order' className='block text-base font-medium leading-6 text-gray-900'>
                  Захиалгын дугаараа оруулна уу
                </label>
                <div className='mt-2'>
                  <input
                    id='order'
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    name='order'
                    type='text'
                    placeholder='******'
                    className='block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 focus:outline-none sm:text-sm sm:leading-6'
                  />
                </div>
                <button
                  onClick={checkOrder}
                  type='button'
                  className='float-right mt-5 rounded-md duration-200 bg-blue-300 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:text-white hover:bg-blue-500'>
                  Шалгах
                </button>
              </div>
            </div>
            <div
              className='duration-500 opacity-0 9fixed 0w-screen 0h-screen 0top-0 0left-0 justify-center items-center bg-black8 bg-opacity-50'
              style={{ display: order === undefined ? 'none' : 'flex', opacity: 1 }}>
              <div className='max-w-[80vw] max-h-[80vh] bg-white border shadow px-6 py-4 rounded-2xl'>
                {order ? (
                  <>
                    <img
                      className='w-full max-w-[400px] h-auto mb-5'
                      src={
                        order['Хүргэгдсэн'] === 'Бэлдэж буй'
                          ? '/pending.png'
                          : order['Хүргэгдсэн'] === 'Хүргэхэд бэлэн'
                          ? '/ready to delivery.png'
                          : order['Хүргэгдсэн'] === 'Хүргэлтэнд гарсан'
                          ? '/delivering.png'
                          : order['Хүргэгдсэн'] === 'Хүргэгдсэн'
                          ? '/delivered.png'
                          : '/notfound.png'
                      }
                      alt='status'
                    />
                    <div></div>
                    <h1 className='text-center'>Таны захиалга {order['Хүргэгдсэн']}</h1>
                  </>
                ) : (
                  <>
                    <img className='w-full max-w-[400px] h-auto mb-5' src={'/checking.jpg'} alt='status' />
                    <h1 className='text-center'>Захиалгын дугаар одоогоор шалгагдаж байна</h1>
                  </>
                )}
                <div className='flex justify-center'>
                  <button
                    onClick={() => {
                      setOrder(undefined);
                    }}
                    type='button'
                    className='mx-auto mt-5 rounded-md duration-200 bg-gray-300 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:text-white hover:bg-gray-500'>
                    Хаах
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-5 p-10 xl:p-40 py-16'>
          <AboutSection
            img={'/about-us.jpg'}
            title={'Бидний тухай'}
            descs={[
              'Эм ди пост ххк нь тээвэр зуучилал, карго тээвэр, дотоод хүргэлт бүхий үйл ажиллагаагаа тогтвортой явуулж байна.',
              'Тусгай зөвшөөрлийнхөө хүрээнд чанартай, хямд, найдвартай үйлчилгээ хүргэж байна. ',
              'Бид эелдэг харьцаа, чанартай үйлчилгээгээрээ үйлчилүүлэгчийн сэтгэл ханамжийг дээшлүүлэ хүргэлтийн салбарт шинэ стандартыг тогтооно.',
            ]}
          />
              
          <AboutSection
            img={'/vision.jpg'}
            title={'Алсын хараа'}
            descs={[
              'Үйлчлүүлэгч төвтэй үйл ажиллагаа явуулж, чанартай бараа, үйлчилгээг үзүүлнэ',
              ' Бизнесийн түншүүдтэйгээ харилцан ашигтай байх зарчмыг баримталж, урт хугцаанд хариуцлагатай хамтрах стратегийн түншлэлийг бий болгоно',
            ]}
            reverse
          />
              
          <AboutSection
            img={'/sign.jpg'}
            title={'Үнэт зүйлс'}
            descs={['Бид хэрэглэгчийн хүсэл сонирхлыг судалж, түүнд нийцсэн бүтээгдэхүүн, үйлчилгээг нэвтрүүлнэ.']}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
